// General Classes
.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.display-block {
  display: block;
}

.display-block--xs {
  @include respondto(phone) {
    display: block;
  }
}

.display-inline-block {
  display: inline-block;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

.clearfix {
  clear: both;
}

.hide {
  display: none;
}

.no-wrap {
  white-space: nowrap;
}

.text-center--xs {
  @include respondto(phone) {
    text-align: center;
  }
}

.full-width {
  width: 100%;
}

.full-width--xs {
  @include respondto(phone) {
    width: 100%;
  }
}

.visible-portrait,
.visible-landscape {
  @include respondto(phone) {
    display: none;
  }
}

.visible-portrait {
  @media screen and (max-width:767px) and (orientation:portrait) {
    display: block;
  }
}

.visible-landscape {
  @media screen and (max-width:767px) and (orientation:landscape) {
    display: block;
  }
}

// Object fit
.object-fit-cover {
  object-fit: cover;
  font-family: 'object-fit: cover; object-position: center';
}

.object-position-top {
  object-position: top;
}

.object-position-center {
  font-family: 'object-position: center center';
  object-position: center center;
}

.background-position {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

// Margins
@for $i from 1 through 6 {
  .margin-top-#{$i} {
    margin-top: #{$i}rem;
  }
}

@for $i from 1 through 6 {
  .margin-bottom-#{$i} {
    margin-bottom: #{$i}rem;
  }
}

@for $i from 1 through 6 {
  .margin-top-#{$i}--xs {
    @include respondto(phone) {
      margin-top: #{$i}rem;
    }
  }
}

@for $i from 1 through 6 {
  .margin-bottom-#{$i}--xs {
    @include respondto(phone) {
      margin-bottom: #{$i}rem;
    }
  }
}

// Paddings
@for $i from 1 through 6 {
  .padding-top-#{$i} {
    padding-top: #{$i}rem;
  }
}

@for $i from 1 through 6 {
  .padding-bottom-#{$i} {
    padding-bottom: #{$i}rem;
  }
}

@for $i from 1 through 6 {
  @include respondto(phone) {
    .padding-top-#{$i}--xs {
      padding-top: #{$i}rem;
    }
  }
}

@for $i from 1 through 6 {
  @include respondto(phone) {
    .padding-bottom-#{$i}--xs {
      padding-bottom: #{$i}rem;
    }
  }
}

.no-margin {
  margin: 0;
}

.no-padding-xs {
  @include respondto(phone) {
    padding: 0;
  }
}

.no-margin-xs {
  @include respondto(phone) {
    margin: 0;
  }
}

// Borders
.top-border {
  background-color: palette(primary);
  display: block;
  height: 2px;
  margin-bottom: 1.5rem;
  width: 8rem;
}

.green-bottom-border {
  border-bottom: 2px solid palette(green);
}

.border-top-none--xs {
  @include respondto(phone) {
    border-top: none;
  }
}
