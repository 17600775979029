.customers-block {
  background-color: palette(grey);
  position: relative;
  padding-bottom: 14rem;
  padding-top: 13rem;

  @include respondto(phone) {
    padding: 0 0 10rem 0;
  }

  &:before {
    background-color: palette(grey);
    content: '';
    position: absolute;
    top: -7rem;
    left: 0;
    height: 7rem;
    width: 100%;
    z-index: -1;

    @include respondto(phone) {
      height: 10rem;
      top: -10rem;
    }
  }

  p {
    color: palette(black);
    font-size: 1.6rem;
    letter-spacing: .5rem;
    margin-bottom: 8rem;

    @include respondto(phone) {
      margin-bottom: 5rem;
    }
  }

  .img-wrapper {
    padding: 0 10rem;

    @include respondto(desktop) {
      padding: 0 20rem;
    }
  }

  .top-border {
    background-color: palette(green);
    margin-bottom: 3rem;
    width: 4rem;
  }
}