@include font-face('zeeky-icons', '../resources/icons/zeeky-icons');

// LibreFranklin
@include font-face('post-grotesk-book', '../resources/fonts/post-grotesk-book/post-grotesk-book');

.font-narrow-regular {
  font-family: titling-gothic-fb-narrow,sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-normal-black {
  font-family: titling-gothic-fb,sans-serif;
  font-weight: 800;
  font-style: normal;
}

$font-sizes: (14, 16, 18, 24, 28, 32, 40);

@each $font-size in $font-sizes {
  .font-#{ $font-size } {
    font-size: #{ ($font-size / 10) + 'rem' };
  }
}

@each $font-size in $font-sizes {
  .font-#{ $font-size }--xs {
    @include respondto(phone) {
      font-size: #{ ($font-size / 10) + 'rem' };
    }
  }
}

// Flex dimensions
@each $dimmension in 14, 18, 24, 28, 40 {
  .flex-#{$dimmension} {
    @include flex-percentage(#{ $dimmension + '%'});

    @include respondto(phone) {
      @include flex-percentage();
    }
  }
}

.flex-100--xs {
  @include respondto(phone) {
    @include flex-percentage();
  }
}
