/**
 * Media Queries
 */
@mixin respondto($device) {
  @each $name, $point in $breakpoints {
    @if $name == $device {
      @media #{$point} {
        @content;
      }
    }
  }
}

/**
 * Font face mixin
 */
@mixin font-face($font-family, $file-path, $font-weight:'normal', $font-style:'normal') {
  @font-face {
    font-family: $font-family;
      src: url('#{$file-path}.eot');
      src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
           url('#{$file-path}.woff') format('woff'),
           url('#{$file-path}.ttf') format('truetype'),
           url('#{$file-path}.svg##{$font-family}') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
  }
  // Chrome for Windows rendering fix: http://www.adtrak.co.uk/blog/font-face-chrome-rendering/
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
      font-family: $font-family;
        src: url('#{$file-path}.svg##{$font-family}') format('svg');
    }
  }
  .font-#{ $font-family } {
    font-family: $font-family;
  }
}

/*------------------------------------------------------------------
Colors
------------------------------------------------------------------*/

@function palette($palette, $tone: 'base') {
  @return map-get(map-get($palettes, $palette), $tone);
}

@mixin generateColorClasses($colorName) {
  @each $tone, $value in map-get($palettes, $colorName) {
    @if ($tone == 'base') {
      &__color {
        color: palette($colorName, $tone);
      }
      &__bg {
        background-color: palette($colorName, $tone);
      }
      &__hover-color {
        &:hover {
          color: palette($colorName, $tone);
        }
        @include transition();
      }
      &__hover-bg {
        &:hover {
          background-color: palette($colorName, $tone);
        }
        @include transition();
      }
    } @else {
      &__color--#{$tone} {
        color: palette($colorName, $tone);
      }
      &__bg--#{$tone} {
        background-color: palette($colorName, $tone);
      }
      &__hover-color--#{$tone} {
        &:hover {
          color: palette($colorName, $tone);
        }
        @include transition();
      }
      &__hover-bg--#{$tone} {
        &:hover {
          background-color: palette($colorName, $tone);
        }
        @include transition();
      }
    }
  }
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}

/**
* TRANSITION
*/
@mixin transition($properties...) {

  @if length($properties) >= 1 {
    transition: $properties;
  } @else {
    transition: all .2s ease-in-out 0s;
  }
}

/**
 * Flexbox Container
 */
@mixin flex($wrap: nowrap, $direction: row, $justify: flex-start, $align: stretch, $content: stretch, $order: 1, $grow: 1) {
  display: flex;

  @if $wrap != nowrap {
    flex-wrap: $wrap;
  }
  @if $direction != row {
    flex-direction: $direction;
  }
  @if $justify != flex-start {
    justify-content: $justify;
  }
  @if $align != stretch {
    align-items: $align;
  }
  @if $content != stretch {
    align-content: $align;
  }
  @if $order != 1 {
    order: $order;
  }
  @if $grow != 1 {
    order: $grow;
  }
}

@mixin flex-percentage($percentage: 1) {
  @if $percentage != 1 {
    box-sizing: border-box;
    flex: 1 1 $percentage;
    max-width: $percentage;
  } @else {
    box-sizing: border-box;
    flex: 1;
    max-width: 100%;
  }
}