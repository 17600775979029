header {
  padding: 4rem 0;

  @include respondto(phone) {
    padding: 1.2rem 0;

    img {
      max-width: 77%;
    }
  }
}