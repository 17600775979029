.button {
  border: none;
  border-radius: 5px;
  display: inline-block;
  font-family: 'post-grotesk-book';
  font-size: 1.4rem;
  padding: 1.5rem 2.5rem;
  text-transform: uppercase;

  @include transition();

  &:hover,
  &:focus {
    text-decoration: none;
  }


  &-primary {
    background-color: palette(primary);
    color: palette(green);

    &:hover,
    &:focus {
      background-color: palette(primary);
      color: palette(white);
    }
  }

  &-lg {
    padding: 2rem 4rem;
  }


  &[disabled] {
    background-color: palette(gray, light);
    color: palette(primary)
  }
}