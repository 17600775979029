.columns-block {
  >.container {
    position: relative;
    top: -2rem;

    @include respondto(tablet) {
      top: -5rem;
    }

    @include respondto(phone) {
      top: -6rem;
    }
  }

  .item-block {
    background-color: palette(white);
    padding: 3rem 3rem 7rem 3rem;

    @include respondto(phone) {
      padding: 3rem 0 4rem 0;

      &:first-of-type {
        padding-top: 5rem;
      }
    }

    &.second-block,
    &.third-block {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        background-color:palette(white);
      }

      @include respondto(phone) {
        padding-top: 0;
      }
    }

    &.second-block {
      &:before {
        top: -2rem;
        height: 2rem;

        @include respondto(tablet) {
          top: 0;
          height: 0;
        }
      }
    }

    &.third-block {
      &:before {
        top: -4rem;
        height: 4rem;

        @include respondto(tablet) {
          top: -2rem;
          height: 2rem;
        }
      }

      @include respondto(phone) {
        padding-bottom: 8rem;
      }
    }
  }

  .title-item {
    color: palette(primary);

    span {
      &:first-of-type {
        color: palette(green);
        font-family: titling-gothic-fb,sans-serif;
        font-weight: 800;
        font-style: normal;
        margin-right: 1rem;
      }
    }
  }

  p {
    font-size: 1.6rem;
  }
}