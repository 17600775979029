.form-block {
  background-color: palette(yellow);
  padding: 11rem 0 9rem 0;

  @include respondto(phone) {
    padding: 6rem 0;
  }

  >img {
    position: absolute;
    top: -2.2rem;
    left: 15rem;

    @include respondto(desktopSmallAndTablet) {
      left: 10rem;
      max-width: 50%;
    }

    @include respondto(phone) {
      left: 2rem;
      max-width: 28rem;
      top: -1.5rem;
    }
  }

  .title-block {
    @include respondto(desktop) {
      padding-right: 12rem;
    }

    @include respondto(desktopSmall) {
      padding-right: 8rem;
    }

    @include respondto(tablet) {
      padding-right: 5rem;
    }
  }

  h2,
  p {
    text-align: right;
  }

  h2 {
    font-size: 8.4rem;

    @include respondto(phone) {
      font-size: 5.6rem;
      text-align: left;
    }

    span {
      display: block;
      line-height: 8rem;

      @include respondto(phone) {
        line-height: 5rem;
      }
    }
  }

  p {
    color: palette(primary);
    float: right;
    line-height: 3.2rem;

    @include respondto(desktop) {
       max-width: 80%;
    }

    @include respondto(desktopSmall) {
      max-width: 78%;
    }

    @include respondto(phone) {
      float: none;
      font-size: 1.6rem;
      line-height: 2.2rem;
      text-align: left;
    }
  }

  input {
    height: 58px;
    margin-bottom: 2.5rem;
    padding-top: 1.2rem;
    width: 385px;

    @include respondto(maxTablet) {
      width: 100%;
    }
  }

  button {
    margin-top: 2.5rem;
  }
}