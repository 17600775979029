[class^='icon-'] {
  flex-shrink: 0;
}
//DO NOT CSS COMB THIS PART
[class^='icon-']:before,
[class*='icon-']:before {
  display: flex;
  align-items: center;
  // display: inline-block;
  // width: 1em;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  height: inherit;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'zeeky-icons' !important;
  /* fix buttons height, for twitter bootstrap */
  line-height: inherit;

  speak: none;
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\ea90";
}

.icon-twitter:before {
  content: "\ea96";
}
.icon-linkedin2:before {
  content: "\eaca";
}



