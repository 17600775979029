html {
  height: 100%;
  -ms-overflow-style: scrollbar;
}

html,
body {
  font-family: 'post-grotesk-book';
  font-size: 62.5%;

  @include respondto(desktopSmall) {
    font-size: 57.5%;
  }

  @include respondto(tablet) {
    font-size: 55%;
  }
}

body {
  font-size: 1.8rem;
  line-height: 1.8rem;
  transition: transform .5s ease-in-out;
}

p,
ul li {
  color: palette(black);
  font-size: 1.8rem;
  line-height: 2.4rem;
}

a {
  background-color: inherit;

  @include transition();

  &:hover,
  &:focus {
    background-color:inherit;
    color: palette(primary, light);
  }
}

iframe {
  border: 0;
}

h1,
h2,
h3,
h4,
a,
span,
li,
div,
button {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: 1.3em;
}

h1,
h2,
h3,
h4,
p,
span,
a {
  letter-spacing: .5px;
}

h1,
h2,
h3,
h4 {
  color: palette(primary);
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

textarea {
  resize: none;
}

.form-control {
  border: none;
  border-radius: 0;
  font-size: 1.8rem;
}

@include placeholder {
  font-family: 'post-grotesk-book';
  font-size: 2rem;
}

@include respondto(desktop) {
  .container {
    width: 1250px;
  }
}


.external-popout {
  background-color: palette(white);
  border: 1px solid palette(primary);
  border-radius: 3px;
  color: palette(primary);
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.2em;
  padding: 1rem;
  position: absolute;
  left: -10rem;
  word-break: break-word;
  text-transform: none;
  transform: translateY(calc(-100% - .5rem));
  white-space: nowrap;
  z-index: 1000;
}