// $z-index: 5;

/**
 * BCell colors
 */
$palettes: (
  white:(
   base: #fff
  ),
  black:(
   base: #282c31,
  ),
  primary:(
   base: #033861,
  ),
  green: (
    base: #5CE0D8
  ),
  grey: (
    base: #f4f3ef,
    light: #ededed,
  ),
  yellow: (
    base: #FFCF43
  )
);

/**
 * Media Queries
 */
$breakpoints: (
  largeDesktop: '(min-width: 1920px)',
  desktop: '(min-width: 1300px)',
  desktopSmall: '(min-width: 992px) and (max-width: 1299px)',
  desktopSmallCustom: '(min-width: 992px) and (max-width: 1199px)',
  onlyDesktop: '(min-width: 992px)',
  maxTablet: '(max-width: 991px)',
  tablet: '(min-width: 768px) and (max-width: 991px)',
  phone: '(max-width: 767px)',
  phoneSmall: '(max-width: 480px)',
  iphone5: '(max-width: 350px)',

  desktopAndTablet: '(min-width: 768px)',
  desktopSmallAndTablet: '(min-width: 768px) and (max-width: 1299px)'
);
