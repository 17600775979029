footer {
  padding: 1.5rem 0 2rem 0;

  p {
    color: palette(primary);
    font-size: 1.2rem;
    margin: 0;
  }

  span {
    color: palette(green);
    font-size: 2.5rem;

    @include transition();
  }

  a {
    &:hover,
    &:focus {
      text-decoration: none;

      span {
        color: palette(primary);
      }
    }
  }

  li {
    a {
      display: block;
      margin-left: 2rem;
    }
  }
}