.intro-block {
  background-color: palette(yellow);
  padding: 8rem 0 9.8rem 0;

  @include respondto(phone) {
    padding: 3rem 0 0 0;

    .container {
      overflow: hidden;
      padding-bottom: 33rem;
    }
  }

  .main-img {
    right: -1rem;
    top: -14rem;
    max-width: 48rem;

    @include respondto(desktop) {
      max-width: 52rem;
    }

    @include respondto(desktopSmall) {
      top: -5rem;
      right: 0;
    }

    @include respondto(maxTablet) {
      right: 0;
    }

    @include respondto(tablet) {
      top: initial;
      bottom: -10rem;
      max-width: 50%;
    }

    @include respondto(phone) {
      bottom: 0;
      max-width: 26rem;
      right: -9rem;
      top: initial;
    }
  }

  .border-img {
    right: 2rem;
    top: 0;

    @include respondto(phone) {
      bottom: 10rem;
      max-width: 3rem;
      right: -1rem;
      top: initial;
    }
  }

  span {
    color: palette(primary);
    font-size: 2.8rem;

    @include respondto(phone) {
      font-size: 2.4rem
    }
  }

  h1 {
    font-family: titling-gothic-fb,sans-serif;
    font-size: 14rem;
    font-weight: 800;
    font-style: normal;
    line-height: 12rem;

    @include respondto(tablet) {
      font-size: 9rem;
      line-height: 8rem;
    }

    @include respondto(phone) {
      font-size: 6.4rem;
      line-height: 5.5rem;
    }
  }

  .button {
    padding: 1.5rem 4rem 1rem 4rem;
  }

  p {
    color: palette(primary);
    line-height: 3.2rem;
    max-width: 60rem;

    @include respondto(desktop) {
      margin-left: 10rem;
    }

    @include respondto(desktopSmall) {
      max-width: 50rem;
    }

    @include respondto(tablet) {
      max-width: 40rem;
    }
  }
}