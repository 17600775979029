/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
@import "../bower_components/bootstrap-sass-official/assets/stylesheets/_bootstrap.scss";
@import "../bower_components/object-fit-images/preprocessors/mixin.scss";
// endbower

// bower:css
@import "../bower_components/components-font-awesome/css/font-awesome.css";
// endbower


/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "_core/_1-variables.scss";
@import "_core/_2-mixins.scss";
@import "_core/_buttons.scss";
@import "_core/_flex.scss";
@import "_core/_fonts.scss";
@import "_core/_general-classes.scss";
@import "_core/_global.scss";
@import "_core/_icons.scss";
@import "partials/_1-header.scss";
@import "partials/_2-footer.scss";
@import "pages/Homepage/_columns-block.scss";
@import "pages/Homepage/_customers-block.scss";
@import "pages/Homepage/_form-block.scss";
@import "pages/Homepage/_intro-block.scss";
@import "pages/Homepage/_why-zeeky.scss";
@import "pages/Homepage/_zeeky-agencies.scss";
// endinjector