.why-zeeky {
  padding-top: 11rem;
  position: relative;

  &:before {
    background-color: palette(yellow);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 10rem;

    @include respondto(desktop) {
      width: 11%;
    }

    @include respondto(phone) {
      height: 18rem;
      width: 100%;
    }
  }

  &:after {
    background-color: palette(yellow);
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 35%;
    height: 34rem;
    z-index: -1;

    @include respondto(desktopSmall) {
      height: 28rem;
      width: 25%;
    }

    @include respondto(tablet) {
      height: 23rem;
      width: 30%;
    }

    @include respondto(phone) {
      height: 24rem;
      width: 100%;
    }
  }


  h2 {
    font-size: 8.4rem;
    line-height: 8rem;
    text-align: right;

    @include respondto(onlyDesktop) {
      margin-top: 9rem;
    }

    @include respondto(phone) {
      font-size: 5.6rem;
      line-height: 5rem;
      text-align: left;
    }
  }

  h2,
  .link-wrapper {
    @include respondto(desktop) {
      max-width: 85%;
    }

    @include respondto(desktopSmall) {
      max-width: 90%;
    }
  }

  span,
  li {
    text-transform: uppercase;
  }

  span {
    color: palette(primary);
    font-family: titling-gothic-fb-narrow,sans-serif;
    letter-spacing: .3rem;
  }

  p {
    line-height: 3.2rem;
  }

  ul {
    padding-left: 6rem;

    @include respondto(phone) {
      padding-left: 3rem;
    }

    li {
      font-size: 1.4rem;
      letter-spacing: .1rem;
      line-height: 3.5rem;
      padding-left: 1rem;

      @include respondto(phone) {
        line-height: 2.5rem;
      }
    }
  }

  .border-img {
    left: 14%;
    top: -5px;
    z-index: 1;

    @include respondto(desktopSmall) {
      top: -5rem;
    }

    @include respondto(phone) {
      left: 1rem;
      max-width: 2.8rem;
      top: -14px;
    }
  }

  .img-wrapper {
    >img {
      &:first-of-type {
        position: relative;
        z-index: 2;

        @include respondto(desktop) {
          margin-top: 2rem;
        }

        @include respondto(desktopSmallCustom) {
          margin-top: 13rem;
        }

        @include respondto(tablet) {
          margin-top: 32rem;
        }

        @include respondto(phone) {
          left: -5rem;
          max-width: 110%;
        }
      }
    }
  }

  .row {
    &:last-child {
      @include respondto(phone) {
        @include flex($direction: column);
      }
    }

    @include respondto(phone) {
      .img-wrapper {
        order: 2;
      }

      .content-block {
        order: 1;

        .button {
          margin: 0 auto 6rem auto;
          max-width: 18rem;
        }
      }
    }
  }
}