.zeeky-agencies {
  background-color: palette(grey);
  padding: 16rem 0 18rem 0;

  @include respondto(phone) {
    padding: 6rem 0 8rem 0;
  }

  h3,
  p {
    @include respondto(desktop) {
      padding-left: 20rem;
    }
  }

  h3 {
    color: palette(primary);
    letter-spacing: .4rem;
  }

  p {
    line-height: 3.2rem;
    max-width: 68%;

    @include respondto(onlyDesktop) {
      margin-right: 11rem;
    }

    @include respondto(tablet) {
      margin-right: 5rem;
    }

    @include respondto(phone) {
      max-width: 100%;
    }
  }

  a {
    @include respondto(desktop) {
      margin-right: 10rem;
    }
  }
}